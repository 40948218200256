






































































import { Component } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mixins } from 'vue-class-component'

// Components
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import ProgressCharacter from '@/components/views/progress/character/ProgressCharacter.vue'
import OptionWithMark from '@/components/_uikit/controls/OptionWithMark.vue'
// Store
import MasterCharacterModule from '@/store/modules/master/character'
// Types
import { CharacterUpdateRequest, ItemLargeResource, ItemType } from '@/store/types/schema.progress'
// Mixins
import MasterCharacterMixin from '@/mixins/master/progress/MasterCharacterMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

enum Steps {
  FIRST_STEP = 0,
  SECOND_STEP = 1,
}

@Component({
  components: {
    OptionWithMark,
    ProgressCharacter,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FirstVisitForm extends mixins(MasterCharacterMixin, NotifyMixin) {
  private currentStep = Steps.FIRST_STEP

  private form: CharacterUpdateRequest = {
    about: '',
    equipments: [],
    username: '',
  }

  private characterColor: 'blue' | 'purple' = 'purple'

  private get characterClass () {
    return [
      'character-sprite',
      'is-animating',
      'character-sprite--small',
      `character-sprite--${this.characterColor}`,
    ]
  }

  mounted() {
    this.form = {
      ...this.character,
    }
  }

  private get colors(): ItemLargeResource[] {
    return MasterCharacterModule.inventory.filter(item => {
      return item.type === ItemType.CHARACTER_COLOR && item.attributes
    })
  }

  private get isFirstStep() {
    return this.currentStep === Steps.FIRST_STEP
  }

  private get isSecondStep() {
    return this.currentStep === Steps.SECOND_STEP
  }

  private get text() {
    if (this.isFirstStep) {
      // return 'Это твой виртуальный аватар в школе СМИТАП. Обучаясь с нами и преодолевая уровни разной сложности, ты будешь получать награды и сможешь изменять своего аватара. Не забудь придумать ему имя.😉'
      return 'Это твой виртуальный аватар в школе СМИТАП. Обучаясь с нами и преодолевая уровни разной сложности, ты будешь  получать награды и бонусы.'
    }
    return 'Придумай имя и уникальную подпись своему аватару. Если захочешь, ты сможешь изменить их в любой момент.'
  }

  private get characterSize (): string {
    return this.$vuetify.breakpoint.width > 600 ? 'xs' : 'xxs'
  }

  private get courseId () {
    return this.$route.params.courseID ? this.$route.params.courseID : ''
  }

  private isMarkedColor(color: ItemLargeResource) {
    if (this.form.equipments?.includes(color.uuid)) {
      this.characterColor = color.attributes?.color?.primary === 'B5CFFF' ? 'blue' : 'purple'

      return true
    }
    return false
  }

  private updateEquipment(color: ItemLargeResource) {
    const foundColor = this.colors.find(item => {
      return item.type === color.type && this.form.equipments?.includes(item.uuid)
    })

    if (foundColor) {
      const foundIndex = this.form.equipments?.findIndex(item => item === foundColor.uuid) as number
      this.form.equipments?.splice(foundIndex, 1, color.uuid)
    } else {
      this.form.equipments?.push(color.uuid)
    }
  }

  private goToPreviousStep(): void {
    if (this.isFirstStep) return
    this.currentStep--
  }

  private goToNextStep(): void {
    /*if (this.isSecondStep) {
      this.finishStepsProcess()
      return
    }
    this.currentStep++*/
    this.finishStepsProcess()
  }

  // Note: функция для окончания первичной настройки персонажа
  private finishStepsProcess() {
    MasterCharacterModule.saveCharacter(this.form)
      .then(() => {
        // Note: Для кейса с кликом на ссылку курса с главного дашборда без сохранённого персонажа
        if (this.$route?.query?.redirect) {
          this.$router.push(this.$route.query.redirect.toString())
        } else {
          this.$router.push({ name: 'master.progress.dashboard' })
        }
      })
      .catch(this.notifyError)
  }
}
