





import { MetaInfo } from 'vue-meta'
import { Component, Vue } from 'vue-property-decorator'

// Components - Cases
import FirstVisit from '@/components/views/progress/FirstVisit.vue'

@Component({
  components: {
    FirstVisit,
  },
})
export default class ProgressFirstVisit extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Мои достижения - Первое посещение!',
    }
  }
}
