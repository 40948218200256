












import { Component } from 'vue-property-decorator'

// Mixins
import MasterCharacterMixin from '@/mixins/master/progress/MasterCharacterMixin'
// Components
import FirstVisitForm from '@/components/forms/progress/FirstVisitForm.vue'
import ProgressCharacter from '@/components/views/progress/character/ProgressCharacter.vue'
// Store
import MasterCharacterModule from '@/store/modules/master/character'
import MasterProgressWithCharacterModule from '@/store/modules/master/progress_course'

@Component({
  components: {
    FirstVisitForm,
    ProgressCharacter,
  },
})
export default class FirstVisit extends MasterCharacterMixin {
  private timerID!: ReturnType<typeof setTimeout>
  private spawnTimerID!: ReturnType<typeof setTimeout>
  private transitionDurationTimeout = 3000
  private hideCharacter = false
  private isSpawned = false
  private isReady = false

  private get classes () {
    return {
      'is-animating': this.isSpawned,
      'is-spawning': !this.isSpawned && this.isReady,
    }
  }

  private async mounted() {
    this.$nextTick(async () => {
      if (!MasterCharacterModule.character.equipments?.length) {
        await MasterProgressWithCharacterModule.fetchProgressWithCharacter()
        await MasterCharacterModule.fetchCharacterInventory()
      }

      this.isReady = true
      this.spawnTimerID = this.startSpawnTransition()
    })
  }

  private beforeDestroy() {
    clearTimeout(this.timerID)
    clearTimeout(this.spawnTimerID)
  }

  private startSpawnTransition() {
    return setTimeout(() => {
      this.isSpawned = true
      this.timerID = this.startTransition()
    }, 800)
  }

  private startTransition() {
    return setTimeout(() => {
      this.hideCharacter = true
    }, this.transitionDurationTimeout)
  }
}
